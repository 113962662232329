import { TP, contactsIdentifiers as identifiers } from 'constants/consts';
import { ENTITY_TYPE, ENTITY_MAIN_TABS } from 'constants/index';

import { getCityCountry } from 'utils/globals';
import { getProductionTitleWorkNames, getProductionDateParts } from 'utils/productions';

import { createSeoStringFromArray } from '../../seo';

const getTitle = ({ entity }) => {
  const location = getCityCountry(entity);

  return [entity?.name, location].filter(Boolean).join(', ');
};

const getSEOTitle = ({ entity, activeTab, t }) => {
  const entityNameLocation = getTitle({ entity });

  const metaTitle = () => {
    switch (activeTab?.key) {
      case ENTITY_MAIN_TABS.PERFORMANCES:
        return [`${entityNameLocation}`, `${t(`${TP}.FN_OPERA_SEASONS`)}`, 'Operabase'];
      case ENTITY_MAIN_TABS.VIDEOS: {
        const videosCount = entity?.stats?.media?.videos?.total;
        return [`${videosCount} ${t(`${TP}.FN_VIDEOS_OF`, { entityName: entityNameLocation })}`, 'Operabase'];
      }
      case ENTITY_MAIN_TABS.IMAGES: {
        const imagesCount = entity?.stats?.media?.images?.total;
        return [`${imagesCount} ${t(`${TP}.FN_PHOTOS_OF`, { entityName: entityNameLocation })}`, 'Operabase'];
      }
      case ENTITY_MAIN_TABS.REVIEWS:
        return [`${t(`${TP}.FN_TOP_REVIEWS`)} - ${entityNameLocation}`, 'Operabase'];
      case ENTITY_MAIN_TABS.VENUES:
        return [`${t(`${TP}.FN_VENUES_OF`, { entityName: entityNameLocation })}`, 'Operabase'];
      case ENTITY_MAIN_TABS.CONTACT:
        return [`${t(`${TP}.FN_CONTACT_INFO_TITLE`)} - ${entityNameLocation}`, 'Operabase'];
      case ENTITY_MAIN_TABS.ABOUT:
        return [`${t(`${TP}.FN_EVERYTHING_ABOUT`, { entityName: entityNameLocation })}`, 'Operabase'];
      case ENTITY_MAIN_TABS.RENTALS:
        return [`${t(`${TP}.FN_ORG_PRODUCTION_RENTALS`, { entityName: entityNameLocation })}`, 'Operabase'];
      default:
        return [`${entityNameLocation}`, 'Operabase'];
    }
  };

  return createSeoStringFromArray(metaTitle());
};

const getSEODescription = ({ t, entity, activeTab, productions }) => {
  const entityName = entity?.name;
  const location = getCityCountry(entity);
  const topProduction = productions?.data?.[0];
  const dateRange = getProductionDateParts(topProduction);
  const { workNames, customName } = getProductionTitleWorkNames(topProduction);
  const upcomingSeasonText = dateRange?.years ? [t(`${TP}.FN_UPCOMING_SEASON_YEAR`, { year: dateRange?.years })] : null;
  const watchNowText = entity?.stats?.media?.videos?.exists ? [t(`${TP}.FN_WATCH_NOW`)] : null;

  const orgDesc = () => {
    switch (activeTab?.key) {
      case ENTITY_MAIN_TABS.PERFORMANCES:
        return [
          `${t(`${TP}.VIEW_SCHEDULE`)} ${entity?.name} ${location}`,
          upcomingSeasonText,
          watchNowText,
          customName,
          workNames,
        ];
      case ENTITY_MAIN_TABS.VIDEOS:
      case ENTITY_MAIN_TABS.IMAGES:
      case ENTITY_MAIN_TABS.REVIEWS: {
        let viewText;
        if (activeTab?.key === ENTITY_MAIN_TABS.VIDEOS) {
          viewText = 'FN_ORG_VIDEOS_SEO_DESC';
        } else if (activeTab?.key === ENTITY_MAIN_TABS.IMAGES) {
          viewText = 'FN_ORG_PHOTOS_SEO_DESC';
        } else {
          viewText = 'FN_ORG_REVIEWS_SEO_DESC';
        }
        return [
          `${t(`${TP}.${viewText}`, {
            entityName,
            location,
            ...((customName || workNames?.[0]) && { productionNames: customName || workNames?.[0] }),
          })}`,
        ];
      }
      case ENTITY_MAIN_TABS.CONTACT:
      case ENTITY_MAIN_TABS.ABOUT:
      case ENTITY_MAIN_TABS.RENTALS:
      case ENTITY_MAIN_TABS.VENUES: {
        let viewText;
        if (activeTab?.key === ENTITY_MAIN_TABS.CONTACT) {
          viewText = 'FN_ORG_CONTACT_SEO_DESC';
        } else if (activeTab?.key === ENTITY_MAIN_TABS.ABOUT) {
          viewText = 'FN_ORG_ABOUT_SEO_DESC';
        } else if (activeTab?.key === ENTITY_MAIN_TABS.RENTALS) {
          viewText = 'FN_ORG_RENTAL_SEO_DESC';
        } else {
          viewText = 'FN_ORG_VENUES_SEO_DESC';
        }
        return [
          `${t(`${TP}.${viewText}`, {
            entityName,
            location,
            ...(activeTab?.key === ENTITY_MAIN_TABS.RENTALS && { productionNames: customName || workNames?.[0] }),
          })}`,
        ];
      }
      default:
        return [
          entityName,
          location,
          ...(entity?.stats?.performances?.future?.total
            ? [t(`${TP}.PERFORMANCES_ON_FILE`, { number: entity?.stats?.performances?.future?.total })]
            : []),
          watchNowText,
          customName,
          upcomingSeasonText,
          workNames,
          `${t(`${TP}.VIEW_SCHEDULE`)} ${entity?.name}, ${location} on Operabase`,
        ];
    }
  };

  return createSeoStringFromArray(orgDesc());
};

const getOgImages = ({ entity }) => {
  const ogImages = [];
  const image = entity?.logo?.medium;

  if (image) {
    ogImages.push({
      url: image,
      alt: entity?.name,
    });
  }

  return ogImages;
};

const getLocalBusinessSEO = ({ entity, photos: entityPhotos }) => {
  const country = entity?.country?.name;
  const city = entity?.city?.name;
  const location = {
    country,
    city,
  };

  const name = entity?.name;

  const website = entity?.contacts?.find(
    item =>
      item?.contactType?.id === identifiers?.website &&
      item?.contact_category?.includes(ENTITY_TYPE.COMPANY || ENTITY_TYPE.FESTIVAL) &&
      item?.value,
  );

  const phone = entity?.contacts?.find(
    item =>
      item?.contactType?.id === identifiers?.phone &&
      item?.contact_category?.includes(ENTITY_TYPE.COMPANY || ENTITY_TYPE.FESTIVAL) &&
      item?.value,
  );

  const address = entity?.contacts?.find(
    item =>
      item?.contactType?.id === identifiers?.address &&
      item?.contact_category?.includes(ENTITY_TYPE.COMPANY || ENTITY_TYPE.FESTIVAL) &&
      item?.value,
  );

  const photos = entityPhotos?.data?.map(item => item?.file?.urls?.medium) || [];
  const venue = entity?.venues?.find(item => item?.venue?.lat && item?.venue?.lon)?.venue;

  return {
    type: 'Organization',
    ...(website && { id: website?.value, url: website?.value }),
    name,
    ...(phone && { telephone: phone?.value }),
    address: {
      ...(address && { streetAddress: address?.value, postalCode: address?.value }),
      addressLocality: location?.city,
      addressCountry: location?.country,
    },
    ...(venue && {
      geo: {
        latitude: venue?.venue?.lat,
        longitude: venue?.venue?.lon,
      },
    }),
    ...(photos?.length > 0 && { images: [...photos] }),
  };
};

export default {
  getTitle,
  getSEODescription,
  getOgImages,
  getLocalBusinessSEO,
  getSEOTitle,
};
