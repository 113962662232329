/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';
import invert from 'lodash/invert';
import omit from 'lodash/omit';
import { useMediaQuery } from '@mui/material';
import Overflow from 'rc-overflow';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
// eslint-disable-next-line import/no-cycle
import LinkButton from 'components/uiLibrary/LinkButton';
import { useStickyContext } from 'components/Globals/Layout/StickyContainer';
import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import { ENTITY_TYPE, TP, URL_SLUG_TYPES } from 'constants/index';
import { FILTER_TYPES, FILTER_SLUG_TYPE, FILTER_LABELS } from 'constants/filters';

import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';
import useTracking from 'components/Globals/Analytics';

import classes from './AppliedFilters.module.scss';

const FilterChip = ({ filter, onClick }) => {
  const onClickHandler = useCallback(() => {
    if (onClick) {
      onClick(filter);
    }
  }, [onClick, filter]);

  return (
    <div className={classes.chipWrapper}>
      <div className={classes.chip} onClick={onClickHandler}>
        <div className={classes.chip__label}>
          <Typography size={12} weight="medium">
            {filter?.label}
          </Typography>
        </div>
        <div className={classes.chip__icon}>
          <SpriteIcon icon="close" />
        </div>
      </div>
    </div>
  );
};

const AppliedFilters = ({
  className,
  isLoading,
  wrapIfNotSticky = true,
  clearExcludedTypes = [],
  isVisibleAlways = false,
  trackingData = {},
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const {
    entity,
    entityType,
    filters,
    applyFilters,
    appliedFilterSlugs,
    validFilters,
    resetFilters,
    navigate,
    subPath,
  } = usePageContext();
  const track = useTracking();

  const hideAppliedFilters = useMediaQuery('(max-width: 800px)');
  const isSticky = useStickyContext();
  const filterSlugsToIgnore = useMemo(() => clearExcludedTypes?.map(type => FILTER_SLUG_TYPE[type]), [
    clearExcludedTypes,
  ]);

  const relevantFilters = useMemo(() => {
    if (!validFilters) {
      return [];
    }

    const queryTypeMap = invert(FILTER_SLUG_TYPE);

    return appliedFilterSlugs?.reduce((acc, filterGroup) => {
      if (
        filterGroup?.type === FILTER_SLUG_TYPE[FILTER_TYPES.BOOLEAN_SEARCH] ||
        filterSlugsToIgnore.includes(filterGroup?.type)
      ) {
        return acc;
      }

      const flattenedFilters = filterGroup?.items?.reduce((accItems, { id, name, slug }) => {
        let filterType = queryTypeMap[filterGroup?.type];

        if (filterGroup?.type === URL_SLUG_TYPES.INDIVIDUAL) {
          filterType = validFilters?.find(type => [FILTER_TYPES.CO_CAST, FILTER_TYPES.PROFILE].includes(type));
        }

        if (filterGroup?.type === URL_SLUG_TYPES.ORGANIZATION) {
          const isCurrentEntity = entityType === ENTITY_TYPE.ORGANIZATION && entity?.id === id;

          if (!isCurrentEntity) {
            filterType = validFilters?.find(type =>
              [FILTER_TYPES.CHORUS, FILTER_TYPES.ORCHESTRA, FILTER_TYPES.COMPANY, FILTER_TYPES.FESTIVAL].includes(type),
            );
          }
        }

        if (filterType && validFilters.includes(filterType)) {
          const type = t(FILTER_LABELS[filterType]);
          const idValue = Number.parseInt(id, 10) || id;

          const filter = {
            id: idValue,
            slug,
            label: name || idValue,
            type,
            filterType,
          };

          accItems.push(filter);
        }

        return accItems;
      }, []);

      return [...acc, ...flattenedFilters];
    }, []);
  }, [validFilters, appliedFilterSlugs, filterSlugsToIgnore, t]);

  const onClickHandler = useCallback(
    filter => {
      const filterKey = FILTER_SLUG_TYPE[filter?.filterType];
      const options = filters?.[filterKey]?.filter(item => item?.id !== filter?.id && item?.slug !== filter?.slug);

      applyFilters({
        entity,
        entityType,
        subPath,
        filters: {
          ...omit(filters, filterKey),
          ...(options?.length > 0 && { [filterKey]: options }),
        },
      });
      track.click({
        subComponent: SUB_COMPONENTS.DELETE_CTA,
        ...trackingData,
        meta: {
          [filterKey]: filter?.id,
        },
      });
    },
    [entity, entityType, filters, applyFilters, subPath],
  );

  const onResetFilters = useCallback(
    e => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }

      resetFilters({ entityType, entity, subPath }, filterSlugsToIgnore);

      navigate.scrollTo();
    },
    [navigate, resetFilters, entityType, entity, filterSlugsToIgnore, subPath],
  );

  if (relevantFilters?.length === 0 || isLoading || (hideAppliedFilters && !isVisibleAlways)) {
    return null;
  }

  return (
    <div className={classnames(classes.root, { [className]: !!className })}>
      <Overflow
        className={classes.appliedFilters}
        data={relevantFilters}
        renderItem={item => <FilterChip key={item?.id} filter={item} onClick={onClickHandler} />}
        renderRest={items => (
          <div className={classes.chipWrapper}>
            <LinkButton variant="text" trackingData={trackingData}>
              {t(`${TP}.FN_PLUS_X_MORE_NO_BRACKET`, { number: items?.length })}
            </LinkButton>
          </div>
        )}
        maxCount={isSticky || !wrapIfNotSticky ? 'responsive' : null}
        suffix={
          relevantFilters?.length > 0 ? (
            <LinkButton
              leftIcon={<SpriteIcon icon="replay-bold" />}
              variant="text"
              shape="rectangle"
              onClick={() => onResetFilters()}
              styles={{
                root: classes.clearSearchText,
                icon: classes.clearIcon,
              }}
              disableUnderline
              trackingData={{ subComponent: SUB_COMPONENTS.CLEAR_CTA, ...trackingData }}
            >
              <Typography size={13} weight="medium">
                {t(`${TP}.AS_FILTERS_CLEAR_ALL_BTN`)}
              </Typography>
            </LinkButton>
          ) : null
        }
      />
    </div>
  );
};

export default AppliedFilters;
