import React, {
  Fragment,
  Children,
  isValidElement,
  cloneElement,
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
  createContext,
} from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import Loader from 'components/uiLibrary/Loader';
import LinkButton from 'components/uiLibrary/LinkButton';

import { updateQueryParams } from 'utils/globals';
import usePageContext from 'utils/hooks/usePageContext';
import { useInfiniteQuery } from 'utils/react-query';

import { TP } from 'constants/index';
import { useTranslation } from 'src/i18n';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import classes from './InfiniteListPage.module.scss';

const PAGE_CONTENT_DISPLAY_NAME = 'page-content';
const PAGE_LOADING_DISPLAY_NAME = 'page-loading';
const PAGE_FETCH_OBSERVER_DISPLAY_NAME = 'page-fetch-observer';

const PageFetchContext = createContext();

const PageFetchContextProvider = ({ children, props }) => (
  <PageFetchContext.Provider value={props}>{children}</PageFetchContext.Provider>
);

export const PageFetchObserver = ({ className, children, isDefault = false }) => {
  const { t } = useTranslation();
  const { disabled = false, onIntersect, isLoading = false, hasNextPage = false } = useContext(PageFetchContext);
  const observerElem = useRef(null);
  const onIntersectRef = useRef(null);

  useEffect(() => {
    onIntersectRef.current = onIntersect;
  }, [onIntersect]);

  useEffect(() => {
    const element = observerElem?.current;
    const option = { threshold: 0 };
    let observer;

    if (element) {
      observer = new IntersectionObserver(entries => {
        const [target] = entries;

        if (!isLoading && target.isIntersecting && onIntersectRef?.current) {
          if (!isDefault) {
            observer.unobserve(element);
          }

          onIntersectRef.current();
        }
      }, option);
      observer.observe(element);
    }

    return () => {
      if (observer) {
        observer.unobserve(element);
      }
    };
  }, [isDefault, isLoading]);

  const content = useMemo(() => {
    if (children) {
      return children;
    }

    if (isDefault && hasNextPage && !isLoading && !disabled) {
      return (
        <LinkButton
          variant="text"
          rightIcon={<SpriteIcon icon="expand_more" />}
          leftIcon={<SpriteIcon icon="expand_more" />}
          onClick={() => {
            if (onIntersectRef?.current) {
              onIntersectRef.current();
            }
          }}
        >
          {t(`${TP}.LOAD_MORE`)}
        </LinkButton>
      );
    }

    return null;
  }, [t, children, disabled, isLoading, isDefault, hasNextPage]);

  if (disabled) {
    return content;
  }

  return (
    <div
      className={classnames({ [classes.fetchObserverWrapper]: isDefault, [className]: !!className })}
      ref={observerElem}
    >
      {content}
    </div>
  );
};

PageFetchObserver.displayName = PAGE_FETCH_OBSERVER_DISPLAY_NAME;

export const PageContent = ({ styles, page, children, updatePage, withoutWrapper = false }) => {
  const [isMounted, setIsMounted] = useState(false);
  const observerElem = useRef(null);

  const handleObserver = useCallback(
    entries => {
      const [target] = entries;

      if (target.isIntersecting) {
        if (isMounted) {
          updatePage(page);
        } else {
          setIsMounted(true);
        }
      }
    },
    [isMounted, page, updatePage],
  );

  useEffect(() => {
    const element = observerElem?.current;
    let observer;

    if (!withoutWrapper) {
      const option = { threshold: 0 };
      observer = new IntersectionObserver(handleObserver, option);

      observer.observe(element);
    }

    return () => {
      if (observer) {
        observer.unobserve(element);
      }
    };
  }, [handleObserver, withoutWrapper]);

  if (withoutWrapper) {
    return children;
  }

  return (
    <div className={classnames({ [styles?.page]: !!styles?.page })}>
      <div ref={observerElem} data-page={page} />
      <div className={classnames({ [styles?.content]: !!styles?.content })}>{children}</div>
    </div>
  );
};

PageContent.displayName = PAGE_CONTENT_DISPLAY_NAME;

export const PageLoading = ({ isLoading, defaultLoader = false, children }) => {
  if (isLoading) {
    if (defaultLoader) {
      return <Loader />;
    }

    return children;
  }

  return null;
};

PageLoading.displayName = PAGE_LOADING_DISPLAY_NAME;

const InfiniteListPage = ({
  query,
  children,
  disabled = false,
  disableQueryUpdate = false,
  updateAppliedFilters = false,
  onMount,
  onPageLoad,
}) => {
  const observerElem = useRef(null);
  const { setAppliedFilterSlugs } = usePageContext();

  const router = useRouter();
  const { query: routerQuery } = router;
  const activePage = useMemo(() => parseInt(routerQuery?.page, 10) || 1, [routerQuery]);

  const { data, fetchNextPage, hasNextPage, isFetching: isLoading, isFetchingNextPage } = useInfiniteQuery(query);

  const loadNextPageContent = useCallback(() => {
    if (!disabled && hasNextPage && !isLoading) {
      fetchNextPage();
    }
  }, [disabled, hasNextPage, isLoading, fetchNextPage]);

  useEffect(() => {
    if (onPageLoad && data) {
      const lastPage = data.pages[data.pages.length - 1];
      onPageLoad(lastPage);
    }
  }, [data]);

  useEffect(() => {
    if (updateAppliedFilters) {
      setAppliedFilterSlugs(data?.pages?.[0]?.filters);
    }

    // NOTE: only to be updated when filters change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, updateAppliedFilters]);

  useEffect(() => {
    if (onMount) {
      onMount({ data, hasNextPage });
    }

    // auto scroll to the last page as per the query param page number on initial load only
    if (observerElem?.current && activePage > 1) {
      const onScrollPage = () => {
        observerElem.current.scrollIntoView();

        // update correct page param if page param is greater than total pages
        if (activePage !== data?.pages?.length && !disableQueryUpdate) {
          updateQueryParams({ router, query: { page: data?.pages?.length } });
        }
      };

      window.requestAnimationFrame(onScrollPage);
    }
  }, []);

  const updatePage = useCallback(
    page => {
      if (!disableQueryUpdate && activePage !== page) {
        updateQueryParams({ router, query: { page } });
      }
    },
    [disableQueryUpdate, activePage, router],
  );

  const appendPageProps = useCallback(
    child => {
      if (child?.type?.displayName === PAGE_CONTENT_DISPLAY_NAME) {
        return data?.pages?.map(({ page, data: pageData }) => (
          <Fragment key={page}>
            {cloneElement(child, {
              updatePage,
              page,
              ...(typeof child?.props?.children === 'function' && {
                children: child?.props?.children({
                  page,
                  data: pageData,
                }),
              }),
            })}
          </Fragment>
        ));
      }

      if (child?.type?.displayName === PAGE_LOADING_DISPLAY_NAME) {
        return cloneElement(child, {
          isLoading,
          ...(typeof child?.props?.children === 'function' && {
            children: child?.props?.children({ isLoading, isFetchingNextPage, hasNextPage }),
          }),
        });
      }

      return child;
    },
    [data, isLoading, isFetchingNextPage, hasNextPage, updatePage],
  );

  const recursiveMap = useCallback(
    elements => {
      const contentWrapper =
        typeof elements === 'function'
          ? elements({
              pages: data?.pages,
              count: data?.pages?.[0]?.meta?.actualTotal || data?.pages?.[0]?.total || data?.total || 0,
              appliedFilters: data?.pages?.[0]?.filters,
              isLoading,
              isFetchingNextPage,
              hasNextPage,
            })
          : elements;

      return Children.map(contentWrapper, child => {
        if (!isValidElement(child)) {
          return child;
        }

        if ([PAGE_CONTENT_DISPLAY_NAME, PAGE_LOADING_DISPLAY_NAME].includes(child?.type?.displayName)) {
          return appendPageProps(child);
        }

        if (child.props.children) {
          return cloneElement(child, {
            children: recursiveMap(child.props.children),
          });
        }

        return child;
      });
    },
    [appendPageProps, data, isLoading, isFetchingNextPage],
  );

  return (
    <PageFetchContextProvider props={{ disabled, onIntersect: loadNextPageContent, isLoading, hasNextPage }}>
      <>
        {recursiveMap(children)}
        <PageFetchObserver isDefault />
      </>
    </PageFetchContextProvider>
  );
};

export default InfiniteListPage;
