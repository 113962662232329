import get from 'lodash/get';

import { TP, ENTITY_TYPE } from 'constants/index';
import { getWorkName } from 'utils/productionDataHelper';
import { createSeoStringFromArray } from 'utils/seo';
import { getCityCountry, getPageTitle } from 'utils/globals';

const getSEODescription = ({ t, entity, productions }) => {
  const location = getCityCountry(entity);

  const seoDescription = [entity?.name, location].filter(Boolean);

  const number = productions?.total;
  if (number) {
    seoDescription.push(t(`${TP}.NUMBER_OF_PRODUCTIONS_PLACEHOLDER`, { number }));
  }

  if (productions?.data?.length > 0) {
    const production = productions?.data?.[0];
    const productionName = getWorkName(production);
    const work = production?.works?.find(Boolean);
    const composer = get(work, 'composer.name');
    const productionCompany = production?.companies?.find(item => item?.isPartner === false) || {
      name: production?.producerReference,
    };
    const company = production?.isArchived ? false : get(productionCompany, 'name');
    const periode = get(production, 'periode');
    seoDescription.push(productionName, composer, company, periode);
  }

  return createSeoStringFromArray([...seoDescription, t(`${TP}.GET_MORE_INFORMATION`)]);
};

const getSEOTitle = ({ entity }) => {
  const venueName = getPageTitle({ entity, entityType: ENTITY_TYPE.VENUE });

  return [venueName].filter(Boolean).join(', ');
};

export default {
  getSEODescription,
  getSEOTitle,
};
