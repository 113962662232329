import React from 'react';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from 'components/uiLibrary/LinkButton';

import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';
import { useVisitsHistory } from 'utils/search/visitsHistory';
import { route } from 'constants/routes';
import { SEARCH_ENTITY_TYPE_MAP, TP } from 'constants/index';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import PreviewCard from '../PreviewCard';
import classes from './RecentlyVisited.module.scss';

const trackingData = {
  section: SECTIONS.RECENTLY_VISITED,
  component: COMPONENTS.PREVIEW_CARD,
};

const RecentlyVisited = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const recentlyVisitedHistory = useVisitsHistory();
  const { navigate } = usePageContext();

  const data = recentlyVisitedHistory?.map(item => ({
    ...item,
    entityType: SEARCH_ENTITY_TYPE_MAP[item.type],
  }));

  if (data?.length === 0) {
    return null;
  }

  return (
    <div className={classes.recentlyVisited}>
      <div className={classes.recentlyVisited__container}>
        <Typography weight="medium" variant="h3" className={classes.title}>
          {t(`${TP}.RA_RECENTLY_VISITED`)}
        </Typography>
        <ul className={classes.list}>
          {data?.map(visit => (
            <li className={classes.list__item} key={`${visit?.entity?.id}-${visit?.entityType}`}>
              <PreviewCard entity={visit?.entity} entityType={visit?.entityType} trackingData={trackingData} />
            </li>
          ))}
          {data?.length > 5 && (
            <li className={classes.itemCard}>
              <LinkButton
                variant="text"
                isLink
                {...navigate.getLinkProps({ baseRoute: route.VISITS_HISTORY })}
                styles={{ root: classes.itemCard__link }}
                trackingData={{ ...trackingData, component: COMPONENTS.MANAGE_RECENTLY_VISITED_PAGES_CTA }}
              >
                <>
                  <Typography className={classes.itemCard__link_text}>
                    {t(`${TP}.FN_MANAGE_RECENTLY_VISITED_PAGES`)}
                  </Typography>
                  <div className={classes.itemCard__rightIcon}>
                    <SpriteIcon icon="chevron_right" className={classes.itemCard__link_icon} size={20} />
                  </div>
                </>
              </LinkButton>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default RecentlyVisited;
