import { useMemo } from 'react';

import { useSeasonFilters } from 'components/Productions/Display/Seasons';

import productionQueries from 'containers/Productions/queries';
import artistQueries from 'containers/Artist/queries';

import getLinkProps from 'utils/globals/getLinkProps';
import { getBreadcrumbFilterParts } from 'utils/globals';
import { useRouteContext } from 'utils/hooks/useRouteContext';

import usePageContext from 'utils/hooks/usePageContext';
import { getArtistIndexQueryParams, getSelectedProfession } from 'utils/artists';
import { useInfiniteQuery, useQuery } from 'utils/react-query';
import { getH1FilterString } from 'utils/globals/filters';
import { useTranslation } from 'src/i18n';

import {
  TP,
  ENTITY_TYPE,
  ENTITY_MAIN_TABS,
  JSON_LD_TYPES,
  ENTITY_DETAILS_TAB,
  SEASON_TYPES,
  ENTITY_META_DETA_TYPES,
  BASE_PAGE_ROUTES,
} from 'constants/index';

import { getProductionTitle } from '../productions';

import ArtistSEO from './artist';
import OrganisationSEO from './organisation';
import ProductionSEO from './production';
import VideoSEO from './video';
import VenueSEO from './venue';
import WorkSEO from './work';

const ENTITY_SEO_MAP = {
  [ENTITY_TYPE.ARTIST]: ArtistSEO,
  [ENTITY_TYPE.ORGANIZATION]: OrganisationSEO,
  [ENTITY_TYPE.PRODUCTION]: ProductionSEO,
  [ENTITY_TYPE.VENUE]: VenueSEO,
  [ENTITY_TYPE.WORK]: WorkSEO,
};

const getBreadcrumbJsonLd = ({ t, data }) => {
  const breadcrumbs = data
    ?.filter(link => link?.path && link?.path !== '/')
    ?.map((link, index) => {
      let linkUrl = link?.url || link?.path?.url;

      if (!linkUrl && typeof link?.path === 'string') {
        linkUrl = getLinkProps({ path: link?.path })?.url;
      }

      const linkName = t(link?.title) || link?.title;
      return {
        position: index + 1,
        name: linkName?.replace(/"/g, "'")?.trim(),
        item: encodeURI(linkUrl),
      };
    });

  return {
    itemListElements: breadcrumbs,
  };
};

export const useJsonLd = ({ type, entityType, entity, data }) => {
  const { t } = useTranslation('NS_DISPLAY_V4');

  const jsonLD = useMemo(() => {
    switch (type) {
      case JSON_LD_TYPES.BREADCRUMB: {
        return getBreadcrumbJsonLd({ t, data });
      }
      case JSON_LD_TYPES.LOCAL_BUSINESS: {
        if (entityType === ENTITY_TYPE.ORGANIZATION) {
          return OrganisationSEO.getLocalBusinessSEO({ entity });
        }

        return {};
      }
      case JSON_LD_TYPES.WEBPAGE: {
        return ProductionSEO.getWebpageSEO({ t, entity });
      }
      case JSON_LD_TYPES.VIDEO: {
        return VideoSEO.getVideoSEO({ entity });
      }
      case JSON_LD_TYPES.VOD: {
        return VideoSEO.getVodVideoSEO({ t, entity });
      }
      case JSON_LD_TYPES.LIVESTREAM: {
        return VideoSEO.getLsVideoSEO({ t, entity });
      }
      case JSON_LD_TYPES.SOCIAL_PROFILE: {
        if (entityType === ENTITY_TYPE.ARTIST) {
          return ArtistSEO.getSocialProfileSEO({ entity, entityType });
        }

        return {};
      }
      default: {
        return {};
      }
    }
  }, [entity, data, entityType, type, t]);

  return jsonLD;
};

const getBreadcrumbLink = ({ title, linkProps }) => ({
  path: linkProps?.as,
  title,
  url: linkProps?.url,
});

const getBreadcrumbs = ({ appliedFilterSlugs, pageTitle, entityType, entity, tab, routeContext }) => {
  const { title: activeTabName, key: activeTabValue, translatedTitle } = tab || {};
  const filterParts = getBreadcrumbFilterParts({ filters: routeContext?.filters, entityType, entity, tab });
  const h1Filters = getH1FilterString({ filters: appliedFilterSlugs, tabKey: activeTabValue });
  const entityGroup = {
    [ENTITY_TYPE.ARTIST]: `${TP}.m_ARTISTS`,
    [ENTITY_TYPE.COMPANY]: `${TP}.m_OPCOS`,
    [ENTITY_TYPE.FESTIVAL]: `${TP}.m_FESTIVALS`,
    [ENTITY_TYPE.PRODUCTION]: `${TP}.FN_PERFORMANCES`,
    [ENTITY_TYPE.VENUE]: `${TP}.m_VENUES`,
    [ENTITY_TYPE.WORK]: `${TP}.m_WORKS`,
  };

  let customBreadcrumbs = [getBreadcrumbLink({ linkProps: getLinkProps(), title: `${TP}.m_HOME` })];

  if (![ENTITY_TYPE.ORGANIZATION, ENTITY_TYPE.ARTIST].includes(entityType)) {
    customBreadcrumbs = [
      ...customBreadcrumbs,
      getBreadcrumbLink({ linkProps: getLinkProps({ entityType }), title: entityGroup[entityType] }),
    ];
  }

  if (entity) {
    customBreadcrumbs = [
      ...customBreadcrumbs,
      getBreadcrumbLink({ linkProps: getLinkProps({ entityType, entity }), title: pageTitle }),
    ];
  }

  if (activeTabValue && activeTabValue !== ENTITY_MAIN_TABS.HOME) {
    customBreadcrumbs = [
      ...customBreadcrumbs,
      getBreadcrumbLink({
        linkProps: getLinkProps({ entityType, entity, path: activeTabValue }),
        title: h1Filters ? `${translatedTitle || activeTabName} / ${h1Filters}` : activeTabName,
      }),
    ];
  }

  return [...customBreadcrumbs, ...filterParts];
};

export const useBreadcrumbs = ({ entityType, entity, activeTab, appliedFilterSlugs }) => {
  const routeContext = useRouteContext();

  const pageTitle = useMemo(() => {
    if (entity) {
      if (entityType === ENTITY_TYPE.ARTIST) {
        return ArtistSEO.getTitle({ entity });
      }

      if (entityType === ENTITY_TYPE.ORGANIZATION) {
        return OrganisationSEO.getTitle({ entity, entityType, activeTab });
      }

      if (entityType === ENTITY_TYPE.PRODUCTION) {
        return getProductionTitle(entity, true);
      }

      if (entityType === ENTITY_TYPE.VENUE) {
        return entity?.name;
      }

      if (entityType === ENTITY_TYPE.WORK) {
        return entity?.original_name || entity?.name;
      }

      return entity?.name || '';
    }

    return '';
  }, [entity, entityType, activeTab]);

  return getBreadcrumbs({ pageTitle, entityType, entity, tab: activeTab, routeContext, appliedFilterSlugs });
};

export const getOgImages = ({ entityType, entity }) => {
  if ([ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION, ENTITY_TYPE.PRODUCTION].includes(entityType)) {
    return ENTITY_SEO_MAP[entityType]?.getOgImages({ entity });
  }

  return [];
};

export const useEntitySEO = ({ entityType, entity, activeTab }) => {
  const enabled = !!entity;
  const { filterParams, context, subPath } = usePageContext();
  const { t } = useTranslation('NS_APP_GLOBALS');

  const seasonType = useMemo(() => {
    let type = SEASON_TYPES.CALENDAR_YEAR;

    if (entityType === ENTITY_TYPE.ORGANIZATION) {
      type =
        entity?.metadata?.find(({ name }) => name === ENTITY_META_DETA_TYPES.SEASON)?.value ||
        SEASON_TYPES.CALENDAR_YEAR;
    }

    return type;
  }, [entity, entityType]);
  const seasonId = filterParams?.season_id?.[0];
  const baseFilters = useSeasonFilters({ seasonKey: seasonId, upcoming: false });

  const { data } = useInfiniteQuery(
    productionQueries.getProductions({
      entityType,
      entityId: entity?.id,
      filters: baseFilters,
      limit: 10,
      queryConfig: {
        enabled:
          enabled &&
          (entityType === ENTITY_TYPE.ORGANIZATION ||
            (entityType === ENTITY_TYPE.ARTIST && subPath === ENTITY_DETAILS_TAB.PAST)),
      },
    }),
  );
  const { data: seasonList } = useQuery(
    productionQueries.getSeasons({
      filters: { ...baseFilters, aggregation_season_type: seasonType },
      queryConfig: { enabled: enabled && entityType !== ENTITY_TYPE.PRODUCTION && !!seasonId },
    }),
  );

  const productions = entityType === ENTITY_TYPE.PRODUCTION ? entity : data?.pages?.[0];
  const performanceCount = seasonList?.filter(({ id }) => id === parseInt(seasonId, 0))?.[0]?.perfCount || 0;

  if (!enabled) {
    return null;
  }

  const title =
    ENTITY_SEO_MAP[entityType]?.getSEOTitle({
      t,
      entity,
      entityType,
      activeTab,
      subPath,
      seasonId,
      performanceCount,
      productions,
      context,
    }) || '';
  const description =
    ENTITY_SEO_MAP[entityType]?.getSEODescription({
      t,
      entity,
      activeTab,
      subPath,
      productions,
      seasonId,
      context,
    }) || '';
  const ogImages = getOgImages({ entityType, entity });

  return {
    title,
    description,
    ogTitle: title,
    ...(ogImages?.length > 0 && { ogImages }),
  };
};

export const useBaseSeoData = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { baseRoute, queryParams, paths, basePath } = usePageContext();

  const { data } = useQuery(
    artistQueries.getAllProfessions({
      queryConfig: { enabled: basePath === BASE_PAGE_ROUTES.ARTISTS },
    }),
  );
  const { professions, voiceTypes, instrumentTypes } = data || {};
  const filters = getArtistIndexQueryParams({
    paths,
    queryParams,
    professions,
    voiceTypes,
    instrumentTypes,
  });
  const professionInfo = getSelectedProfession({ paths, professions, voiceTypes, instrumentTypes });

  const { data: professionsList } = useQuery(
    artistQueries.getArtists({
      filters,
      limit: 30,
      queryConfig: { enabled: !!queryParams?.page },
    }),
  );

  if (!baseRoute) {
    return {
      title: t('operabase.general.SEO_TITLE_HOMEPAGE'),
      description: t('operabase.general.SEO_DESC_HOMEPAGE'),
      ogTitle: t('operabase.general.SEO_OG_TITLE_HOMEPAGE'),
    };
  }

  if (baseRoute === BASE_PAGE_ROUTES.ARTISTS) {
    if (paths?.length === 1 && Object.keys(queryParams).length === 0) {
      const title = t('operabase.general.FN_ARTIST_INDEX_PROFESSION_TITLE', { profession: professionInfo?.label });
      return {
        title,
        description: t('operabase.general.FN_ARTIST_INDEX_PROFESSION_DESC', { profession: professionInfo?.label }),
        ogTitle: title,
      };
    }

    if (queryParams?.page && !queryParams?.letter) {
      const endPage = Math.ceil(professionsList?.total / professionsList?.limit);
      const title = t('operabase.general.FN_ARTIST_INDEX_PAGE_FILTER_TITLE', { page: queryParams?.page });
      return {
        title,
        description: t('operabase.general.FN_ARTIST_INDEX_PAGE_FILTER_DESC', {
          startPage: queryParams?.page,
          endPage,
          total: professionsList?.total,
        }),
        ogTitle: title,
      };
    }

    if (queryParams?.letter) {
      const endPage = Math.ceil(professionsList?.total / professionsList?.limit);
      const letter = queryParams?.letter.toUpperCase();
      const title = t('operabase.general.FN_ARTIST_INDEX_LETTER_FILTER_TITLE', {
        page: queryParams?.page,
        letter,
      });

      return {
        title,
        description: t('operabase.general.FN_ARTIST_INDEX_LETTER_FILTER_DESC', {
          startPage: queryParams?.page,
          endPage,
          total: professionsList?.total,
          letter,
        }),
        ogTitle: title,
      };
    }

    return {
      title: t('operabase.general.FN_ARTIST_INDEX_TITLE'),
      description: t('operabase.general.FN_ARTIST_INDEX_DESC'),
      ogTitle: t('operabase.general.FN_ARTIST_INDEX_TITLE'),
    };
  }

  return {
    title: t(`${TP}.m_OPERABASE`),
    description: t(`${TP}.m_OPERABASE`),
  };
};
