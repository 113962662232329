import isEmpty from 'lodash/isEmpty';
import getLinkProps from 'utils/globals/getLinkProps';
import usePageContext from 'utils/hooks/usePageContext';

import {
  dateFormats,
  EVENT_SEO_ATTENDANCE_MODE_OFFILNE,
  EVENT_SEO_OFFERS_AVAILABILITY,
  EVENT_SEO_STATUS_CANCELLED,
  EVENT_SEO_STATUS_SCHEDULED,
} from 'constants/consts';

import { escapeBadCharacter } from 'utils/index';
import { getProductionTitle, getProductionTitleWorkNames, isPastPerformance } from 'utils/productions';
import { createDate, getFormattedDateRange } from 'utils/date';
import { sanitizeStructuredLdJson, createSeoStringFromArray } from 'utils/seo';
import { getSeoProductionName } from 'utils/productionDataHelper';
import {
  TP,
  ENTITY_TYPE,
  CONTRIBUTORS,
  ENTITY_MAIN_TABS,
  DATE_FORMATS,
  PERFORMANCE_DATE_MODE_TYPES,
} from 'constants/index';

export const getNextPerformanceDate = entity => {
  if (isEmpty(entity?.performances)) {
    return null;
  }

  const today = createDate().format(dateFormats.basic);
  const nextPerformanceDate = entity?.performances?.find(item => item?.date >= today);

  return nextPerformanceDate;
};

export const getSEOTitle = ({ activeTab, entity, t, context }) => {
  const { tickets, venues, contributions, stats } = entity || {};
  const productionTitle = getProductionTitleWorkNames(entity);
  const name = productionTitle?.customName || productionTitle?.workNames?.[0];

  const productionCompany = contributions?.find(
    contribution => contribution?.contributionType === CONTRIBUTORS.PRODUCER,
  );
  const cityCountry =
    venues?.[0]?.id !== -1 && venues?.[0]?.city?.name
      ? `, ${venues?.[0]?.city?.name}, ${venues?.[0]?.country?.name}`
      : '';
  const producer = productionCompany?.id === -1 ? '' : `${productionCompany?.organization?.name}, `;
  const dates = getFormattedDateRange({
    from: entity?.minDate,
    to: entity?.maxDate,
    dateFormat: DATE_FORMATS.MONTH_DAY_YEAR,
  });
  const isOldPerformance = isPastPerformance(entity);
  const ticketsText = tickets?.length > 0 && !isOldPerformance ? `${t(`${TP}.SEO_BUY_TICKETS`)}` : '';
  const hasLsVod = entity?.performances?.filter(
    date =>
      [PERFORMANCE_DATE_MODE_TYPES.FULL_VIDEO, PERFORMANCE_DATE_MODE_TYPES.LIVESTREAM].includes(date?.mode) &&
      typeof date?.startDate === 'string',
  );
  const videosText = hasLsVod ? `${t(`${TP}.FN_WATCH_ONLINE_VIDEOS`)}` : '';

  if (context?.date?.length > 0) {
    const performanceDate = createDate(context?.date?.[0]?.slug).format(DATE_FORMATS.MONTH_DAY_YEAR);
    let dateVenueProducer = `${performanceDate}`;
    if (venues?.[0]?.id !== -1 && venues?.[0]?.city?.name) {
      dateVenueProducer += ` | ${venues?.[0]?.city?.name} `;
    }
    if (producer) {
      dateVenueProducer += `| ${producer}`;
    }

    let performanceMetaTitle = [];
    if (isOldPerformance) {
      performanceMetaTitle = [`${name}, ${dateVenueProducer}${t(`${TP}.FN_ON`)} Operabase`];
    } else {
      performanceMetaTitle = [
        `${t(`${TP}.FN_UPCOMING_PERFORMANCE_META_TITLE`, { prodName: name, dateVenueProducer })}`,
      ];
    }

    return createSeoStringFromArray(performanceMetaTitle);
  }

  const metaTitle = () => {
    switch (activeTab?.key) {
      case ENTITY_MAIN_TABS.PERFORMANCES: {
        if (isOldPerformance) {
          return [
            `${t(`${TP}.FN_ALL_PERFORMANCES_OF`)} ${name}, ${producer}${dates}${cityCountry} ${t(
              `${TP}.FN_META_TITLE_END_PAST_PERFORMANCES`,
            )}`,
          ];
        }
        return [
          `${t(`${TP}.FN_ALL_PERFORMANCES_OF`)} ${name}, ${producer}${dates}${cityCountry} ${t(
            `${TP}.FN_PROD_OVERVIEW_TERTIARY_SEO_DESC`,
          )}`,
        ];
      }
      case ENTITY_MAIN_TABS.ABOUT: {
        const videoText = stats?.media?.videos?.exists ? `${t(`${TP}.m_VIDEOS`)}` : '';
        const ticketText =
          tickets?.length > 0 && stats?.media?.videos?.exists
            ? ` ${t(`${TP}.AND`)} ${t(`${TP}.FN_TICKETS`)}`
            : `${t(`${TP}.FN_TICKETS`)}`;
        return [
          `${name}, ${producer}${dates}${cityCountry}, ${t(`${TP}.FN_PERFORMANCES`)}, ${videoText}${
            tickets?.length > 0 ? ticketText : ''
          }`,
          'Operabase',
        ];
      }
      case ENTITY_MAIN_TABS.VIDEOS: {
        const videosCount = entity?.stats?.media?.videos?.total;
        return [
          `${videosCount} ${t(`${TP}.FN_VIDEOS_OF`, {
            entityName: name,
          })}, ${producer}${dates}${cityCountry}`,
          'Operabase',
        ];
      }
      case ENTITY_MAIN_TABS.IMAGES: {
        const imagesCount = entity?.stats?.media?.images?.total;
        return [
          `${imagesCount} ${t(`${TP}.FN_PHOTOS_OF`, {
            entityName: name,
          })} ${producer}${dates}${cityCountry}`,
          'Operabase',
        ];
      }
      case ENTITY_MAIN_TABS.REVIEWS: {
        return [
          `${t(`${TP}.FN_SEO_REVIEWS_META_TITLE_PROD`, {
            prodName: name,
            date: dates,
            ...(venues?.[0]?.city?.name && { venueCity: venues?.[0]?.city?.name }),
            producer,
          })}`,
        ];
      }
      case ENTITY_MAIN_TABS.CAST_CREW:
        return [
          `${t(`${TP}.FN_CAST_CREW_META_TITLE`, {
            prodName: name,
            yearCityCountry: `${dates}${cityCountry}`,
            company: producer,
          })}`,
        ];
      default:
        return [`${name}, ${producer}${dates}${cityCountry}`, `${videosText}`, `${ticketsText}`, 'Operabase'];
    }
  };

  return createSeoStringFromArray(metaTitle());
};

const getSEODescription = ({ t, entity, activeTab, context }) => {
  const productionTitle = getProductionTitleWorkNames(entity);
  const name = productionTitle?.customName || productionTitle?.workNames?.[0];
  const productionWorks = entity?.productionWorks;
  const work = productionWorks?.[0]?.work;
  const composer = work?.creators?.[0]?.profile?.name || '';
  const productionCompany = entity?.contributions?.find(
    contribution => contribution?.contributionType === CONTRIBUTORS.PRODUCER,
  );
  const producer = productionCompany?.id === -1 ? '' : `${productionCompany?.organization?.name}`;
  const dates = getFormattedDateRange({
    from: entity?.minDate,
    to: entity?.maxDate,
    dateFormat: DATE_FORMATS.MONTH_DAY_YEAR,
  });
  const director = entity?.directors?.[0]?.name
    ? `${t(`${TP}.FN_DIRECTED_BY`, { director: entity?.directors?.[0]?.name })}, `
    : '';
  const conductor = entity?.conductors?.[0]?.name
    ? `${t(`${TP}.FN_CONDUCTED_BY`, { conductor: entity?.conductors?.[0]?.name })}.`
    : '';
  const conductorDirector = conductor || director ? `${director}${conductor}` : '';
  const isOldPerformance = isPastPerformance(entity);

  if (context?.date?.length > 0) {
    if (isOldPerformance) {
      const performanceDesc = `${t(`${TP}.FN_SEO_META_DESC_OLD_PERFORMANCE`, {
        productionName: name,
        date: context?.date?.[0]?.slug,
        seasonYear: dates,
        producer,
        conductorDirector,
      })}`;

      return createSeoStringFromArray([performanceDesc]);
    }
    const performanceDesc = `${t(`${TP}.FN_SEO_META_DESC_PERFORMANCE`, {
      productionName: name,
      date: context?.date?.[0]?.slug,
      seasonYear: dates,
      producer,
      conductorDirector,
    })}`;

    return createSeoStringFromArray([performanceDesc]);
  }

  const prodDesc = () => {
    const defaultPrimaryText = [
      `${t(`${TP}.FN_PROD_OVERVIEW_PRIMARY_SEO_DESC`, {
        productionTitle: name,
        composer,
        company: producer,
        seasonYear: dates,
      })} ${conductorDirector} ${
        isOldPerformance
          ? t(`${TP}.FN_PROD_OVERVIEW_SECONDARY_SEO_DESC_PAST`)
          : t(`${TP}.FN_PROD_OVERVIEW_SECONDARY_SEO_DESC`)
      }`,
    ];

    switch (activeTab?.key) {
      case ENTITY_MAIN_TABS.IMAGES: {
        const imgConductorDirector = ` ${t(`${TP}.FN_BY_DIRECTED_CONDUCTOR`, {
          conductor: entity?.conductors?.[0]?.name,
          director: entity?.directors?.[0]?.name,
        })}`;
        return [
          `${t(`${TP}.FN_VIEW_PHOTOS_OF`, { entityName: name })}${imgConductorDirector} 
          ${t(`${TP}.FN_CAPTURED_DURING`, {
            seasonYear: dates,
          })}, ${t(`${TP}.FN_PRODUCTION_BY`, {
            producer,
          })} ${t(`${TP}.FN_AND_MUCH_MORE`)}`,
        ];
      }
      case ENTITY_MAIN_TABS.VIDEOS: {
        const videosConductorDirector = ` ${t(`${TP}.FN_BY_DIRECTED_CONDUCTOR`, {
          conductor: entity?.conductors?.[0]?.name,
          director: entity?.directors?.[0]?.name,
        })}`;
        return [
          `${t(`${TP}.FN_VIEW_VIDEOS_OF`, { entityName: name })}${videosConductorDirector}
           ${t(`${TP}.FN_CAPTURED_DURING`, { seasonYear: dates })}, ${t(`${TP}.FN_PRODUCTION_BY`, {
            producer,
          })} ${t(`${TP}.FN_AND_MUCH_MORE`)}`,
        ];
      }
      case ENTITY_MAIN_TABS.REVIEWS:
        return [
          `${t(`${TP}.FN_SEO_REVIEWS_META_DESC_PROD`, {
            productionTitle: name,
            seasonYear: dates,
            producer,
            conductorDirector,
          })}`,
        ];
      case ENTITY_MAIN_TABS.CAST_CREW: {
        const castAndCrew = entity?.contributions
          ?.filter(item => item?.contributionType === CONTRIBUTORS.CAST)
          ?.slice(0, 2)
          ?.map(item => item?.profile?.name)
          ?.join(', ');

        const artistListText =
          castAndCrew?.length > 0 ? `${`${t(`${TP}.FN_CAST_CREW_META_ARTIST`, { artistList: castAndCrew })}`}.` : '';

        return [
          `${t(`${TP}.FN_CAST_AND_CREW_META_DESC_PRIMARY`, { productionTitle: name })}. 
          ${artistListText}
        ${t(`${TP}.FN_CAST_AND_CREW_META_DESC_SECONDARY`, { seasonYear: dates })}, ${conductorDirector}`,
        ];
      }
      case ENTITY_MAIN_TABS.PERFORMANCES:
        return [
          `${t(`${TP}.FN_VIEW_ALL_PERFORMANCES`, { productionTitle: name })}, ${composer}, a ${dates}, 
          ${t(`${TP}.FN_PRODUCTION_BY`, { producer })}, ${conductorDirector} ${t(
            `${TP}.FN_PROD_OVERVIEW_TERTIARY_SEO_DESC`,
          )}`,
        ];
      default:
        return [defaultPrimaryText];
    }
  };

  return createSeoStringFromArray(prodDesc());
};

const getOgImages = ({ entity }) => {
  const ogImages = [];
  const image = entity?.poster?.file?.urls?.medium;

  if (image) {
    ogImages.push({
      url: image,
      alt: getSeoProductionName(entity),
    });
  }

  return ogImages;
};

const useWebpageSEO = ({ t, entity: production }) => {
  const { navigate } = usePageContext();

  const isPerformance = production?.production?.id;
  const type = isPerformance ? production?.production : production;

  const { minDate, date, maxDate, venues } = production || {};
  const startDate = minDate ?? date;
  const endDate = maxDate ? createDate(maxDate).format('YYYY-MM-DD') : '';

  const website = navigate.getLinkProps({
    entityType: ENTITY_TYPE.PRODUCTION,
    entity: type,
    path: createDate(date?.startDate)
      ?.locale('en')
      .format(DATE_FORMATS.URL_FULL_DATE),
  })?.url;
  const venue = Array.isArray(venues) && venues.filter(o => o?.id > 0)?.length > 0 ? production?.venues?.[0] : null;
  const producersList = production?.contributions?.filter(
    contributor => contributor?.contributionType === CONTRIBUTORS.PRODUCER,
  );
  const castCrew = production?.contributions?.filter(contributor =>
    [CONTRIBUTORS.CAST, CONTRIBUTORS.CREW, CONTRIBUTORS.OTHER].includes(contributor?.contributionType),
  );

  const productionTitle = escapeBadCharacter(getProductionTitle(production, true)) || '';
  const seoDescription = [
    productionTitle,
    t(`${TP}.FN_OPERA`),
    ...(castCrew?.length ? [t(`${TP}.FN_CAST_AND_CREW`)] : []),
    ...(production?.tickets?.length ? [t('PERFORMANCE_SCHEDULE_TICKETS')] : []),
    t(`${TP}.VIEW_MORE_INFORMATION`),
  ];
  const productionDescription = createSeoStringFromArray(seoDescription);

  const organizers = producersList?.map(producer => ({
    '@type': producer?.profile === ENTITY_TYPE.ARTIST ? 'Person' : 'Organization',
    name: producer?.organization?.name || producer?.profile?.name,
    url: getLinkProps({
      entity: producer?.organization || producer?.profile,
      entityType: producer?.profile ? ENTITY_TYPE.ARTIST : ENTITY_TYPE.ORGANIZATION,
    })?.url,
  }));
  const performers =
    castCrew
      ?.map(item => ({
        '@type': 'Person',
        name: item?.profile?.name,
        sameAs: navigate.getLinkProps({ entity: item?.profile, entityType: ENTITY_TYPE.ARTIST })?.url,
      }))
      ?.filter(Boolean) || [];

  const boxOfficeWebsiteTickets = production?.tickets?.filter(ticket => ticket?.boxOfficeWebsite);
  const offers = boxOfficeWebsiteTickets?.map(ticket => ({
    '@type': 'Offer',
    priceCurrency: '',
    availability: EVENT_SEO_OFFERS_AVAILABILITY,
    url: encodeURI(ticket?.boxOfficeWebsite),
    price: '',
  }));

  return sanitizeStructuredLdJson({
    type: 'WebPage',
    name: productionTitle,
    description: productionDescription,
    publisher: {
      '@type': 'Organization',
      '@id': 'https://www.operabase.com/en',
      name: 'Operabase',
      legalName: 'Arts Consolidated Aps',
      url: 'https://www.operabase.com/en',
      logo: 'https://www.operabase.com/images/operabase-logo-large.png',
      image: 'https://www.operabase.com/images/operabase-logo-large.png',
      email: 'contact@operabase.com',
      telephone: '-',
      sameAs: [
        'https://www.facebook.com/operabase/',
        'https://twitter.com/BaseOpera',
        'https://en.wikipedia.org/wiki/Operabase',
        'https://www.linkedin.com/company/operabase-global',
        'https://www.instagram.com/operabaseglobal/',
      ],
      foundingDate: '1996',
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'København, Denmark',
        streetAddress: 'Lyngbyvej 20',
        postalCode: '2100',
        addressCountry: 'DK',
      },
    },
    license: 'http://creativecommons.org/licenses/by-nc-sa/3.0/us/deed.en_US',
    mainEntity: {
      '@context': 'https://schema.org',
      '@type': 'Event',
      eventStatus: production?.isCancelled ? EVENT_SEO_STATUS_CANCELLED : EVENT_SEO_STATUS_SCHEDULED,
      eventAttendanceMode: EVENT_SEO_ATTENDANCE_MODE_OFFILNE,
      startDate: startDate ? new Date(startDate).toISOString() : null,
      endDate: endDate ? new Date(endDate).toISOString() : null,
      location: {
        '@type': 'Place',
        ...(venue && {
          address: {
            '@type': 'PostalAddress',
            streetAddress: '',
            addressLocality: '',
            addressRegion: venue?.city?.name || '',
            postalCode: '',
            addressCountry: venue?.country?.code || '',
          },
          name: venue?.name || '',
        }),
      },
      url: website,
      ...(offers?.length > 0 && { offers }),
      ...(organizers?.length > 0 && { organizer: organizers }),
      ...(performers?.length > 0 && { performer: performers }),
      name: productionTitle,
      description: productionDescription,
      image: production?.metaInfo?.img,
    },
    ...(production?.isArchived && {
      isAccessibleForFree: 'False',
      hasPart: [
        {
          type: 'WebPageElement',
          isAccessibleForFree: false,
          cssSelector: 'paywall-content',
        },
      ],
    }),
  });
};

export default {
  getSEODescription,
  getOgImages,
  getWebpageSEO: useWebpageSEO,
  getSEOTitle,
};
