/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo, useCallback } from 'react';
import classnames from 'classnames';

import Content from 'components/Globals/Layout/StubPage/Content';
import Typography from 'components/uiLibrary/Typography';
import LinkButton, { TertiaryButton } from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import SectionBlock, { SectionAdd, SectionEdit } from 'components/Globals/SectionBlock';
import FilterFacets, { FILTER_LAYOUT } from 'components/Filters/Display/FilterFacets';
import AppliedFilters from 'components/Filters/Display/AppliedFilters';
import ProductionListing, {
  ProductionViewModeSwitch,
  VIEW_TYPES,
} from 'components/Productions/Common/ProductionListing';
import queries from 'containers/Productions/queries';

import {
  TP,
  ENTITY_TYPE,
  ENTITY_MAIN_TABS,
  ENTITY_META_DETA_TYPES,
  SEASON_TYPES,
  PERFORMANCE_TYPES,
  ENTITY_DETAILS_TAB,
  VALID_QUERY_PARAMS,
} from 'constants/index';
import { FILTER_TYPES } from 'constants/filters';
import useTracking from 'components/Globals/Analytics';
import usePageContext from 'utils/hooks/usePageContext';
import { useQuery } from 'utils/react-query';
import { getValidFilters } from 'utils/globals/getRouteContext';
import { useTranslation } from 'src/i18n';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { getBaseSeasonFilters } from 'utils/productions';
import { SECTIONS, COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import { createHorizontalSections } from 'utils/globals/app';
import { artistEditTabValues } from 'containers/Artist/constants';
import { PERFORMANCE_SECTION } from 'constants/consts';
import classes from './Seasons.module.scss';

const overviewCreditsTrackingDataSection = {
  section: SECTIONS.OVERVIEW_CREDITS,
};

const upcomingPerformanceHeaderTrackingData = {
  ...overviewCreditsTrackingDataSection,
  component: COMPONENTS.UPCOMING_PERFORMANCES,
  subComponent: SUB_COMPONENTS.HEADER,
};
const pastPerformanceHeaderTrackingData = {
  ...overviewCreditsTrackingDataSection,
  component: COMPONENTS.PAST_PERFORMANCES,
  subComponent: SUB_COMPONENTS.HEADER,
};

const seeAllPerformanceCreditsTrackingData = {
  ...overviewCreditsTrackingDataSection,
  component: COMPONENTS.SEE_ALL_CTA,
};

const overviewCreditsTrackingDataSlug = {
  ...pastPerformanceHeaderTrackingData,
  subComponent: SUB_COMPONENTS.PRODUCTION_SLUG,
};

// On Performances Tab
const upcomingPerformancesTrackingData = {
  section: SECTIONS.UPCOMING_PERFORMANCES,
  component: COMPONENTS.PRODUCTION_SLUG,
};
const pastPerformancesTrackingData = {
  section: SECTIONS.PAST_PERFORMANCES,
  component: COMPONENTS.PRODUCTION_SLUG,
};

const upcomingPerformancesFilterTrackingData = {
  section: SECTIONS.UPCOMING_PERFORMANCES,
  component: COMPONENTS.FILTERS,
};
const pastPerformancesFilterTrackingData = {
  section: SECTIONS.PAST_PERFORMANCES,
  component: COMPONENTS.FILTERS,
};

const upcomingPerformanceSwitchTrackingData = {
  section: SECTIONS.UPCOMING_PERFORMANCES,
  component: COMPONENTS.DAY_SEASON_VIEW_SWITCH,
};

const upcomingPerformanceSeasonSelectorTrackingData = {
  section: SECTIONS.UPCOMING_PERFORMANCES,
  component: COMPONENTS.SEASON_SELECTOR,
};

const pastPerformanceSeasonSelectorTrackingData = {
  section: SECTIONS.PAST_PERFORMANCES,
  component: COMPONENTS.SEASON_SELECTOR,
};

export const useSeasonFilters = ({ upcoming = false, seasonKey } = {}) => {
  const { entityType, entity, filterParams } = usePageContext();
  const baseFilters = useMemo(() => getBaseSeasonFilters({ entityType, entity, upcoming }), [
    entityType,
    entity,
    upcoming,
  ]);

  return {
    ...filterParams,
    ...baseFilters,
    ...(seasonKey && { season_id: seasonKey }),
  };
};

const ProductionListingWrapper = ({ viewMode, upcoming = false, seasonKey, trackingData }) => {
  const { isMobile } = useDeviceTypeLayouts();
  const { mainPath, entity, entityType } = usePageContext();
  const queryFilters = useSeasonFilters({ upcoming, seasonKey });

  const limit = useMemo(() => {
    if (mainPath) {
      return 15;
    }

    if (isMobile) {
      return 3;
    }

    return 6;
  }, [mainPath, isMobile]);

  return (
    <ProductionListing
      entityType={entityType}
      entity={entity}
      filters={queryFilters}
      infinite={!!mainPath}
      viewMode={viewMode}
      limit={limit}
      upcoming={upcoming}
      trackingData={trackingData}
    />
  );
};

const SeasonProductionList = props => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { entity, filterTypeUsage } = usePageContext();
  const { seasonId, seasonsList, seasonRange, upcoming = false, sectionProps = {} } = props;
  const [viewMode, setViewMode] = useState(VIEW_TYPES.PERFORMANCE);

  const { seasonKey, range, productions, performances } = useMemo(() => {
    const selectedSeason = seasonsList?.find(({ id }) => id === seasonId);

    const statsProductions = upcoming
      ? entity?.stats?.productions?.future?.total
      : entity?.stats?.productions?.past?.total;
    const statsPerformances = upcoming ? entity?.stats?.performances?.future?.total : null;

    return {
      seasonKey: selectedSeason?.key,
      range: selectedSeason ? selectedSeason?.name : seasonRange,
      productions: selectedSeason ? selectedSeason?.total : statsProductions,
      performances: selectedSeason ? selectedSeason?.perfCount : statsPerformances,
    };
  }, [
    seasonsList,
    upcoming,
    entity?.stats?.productions?.future?.total,
    entity?.stats?.productions?.past?.total,
    entity?.stats?.performances?.future?.total,
    seasonRange,
    seasonId,
  ]);

  return (
    <div className={classes.productionListing}>
      <div className={classes.productionListing__criteria}>
        <div className={classes.productionListing__header}>
          <Typography className={classes.productionListing__header_title_desktop} variant="p" truncate>
            <Typography weight="bold" color="primary" className={classes.seasonName}>
              {t(`${TP}.FN_PERFORMANCE_FOR_SEASON`)} {range}
            </Typography>
            <Typography color="primary" size={12} className={classes.productionsCount}>
              {productions} {t(`${TP}.m_PROD`)}
              {performances && ` (${performances} ${t(`${TP}.FN_PERFORMANCES`)})`}
            </Typography>
          </Typography>
          <Typography className={classes.productionListing__header_title_mobile} variant="p" size={13} truncate>
            {t(`${TP}.FN_SHOWING_X_PRODUCTIONS`, {
              msg: `${productions} ${t(`${TP}.m_PROD`)}  ${
                performances ? `(${performances} ${t(`${TP}.FN_PERFORMANCES`)})` : ''
              }`,
            })}
          </Typography>
          {upcoming && (
            <ProductionViewModeSwitch
              viewMode={viewMode}
              onChange={setViewMode}
              trackingData={upcomingPerformanceSwitchTrackingData}
            />
          )}
          {sectionProps?.editMode?.enabled && (
            <div className={classes.editModeActions}>
              <SectionEdit {...sectionProps?.editMode} />
              <SectionAdd {...sectionProps?.editMode} />
            </div>
          )}
        </div>
        {!sectionProps?.editMode?.enabled && (
          <>
            <div className={classes.productionListing__filters}>
              <PerformanceFilters
                upcoming={upcoming}
                trackingData={upcoming ? upcomingPerformancesFilterTrackingData : pastPerformancesFilterTrackingData}
              />
            </div>
            <AppliedFilters
              className={classes.productionListing__applied}
              clearExcludedTypes={[FILTER_TYPES.SEASON]}
              trackingData={upcoming ? upcomingPerformancesFilterTrackingData : pastPerformancesFilterTrackingData}
              isVisibleAlways
            />
          </>
        )}
      </div>
      {!filterTypeUsage?.limitExceeded && (
        <div className={classes.productionListing__list}>
          <ProductionListingWrapper
            viewMode={upcoming ? viewMode : VIEW_TYPES.SEASON}
            upcoming={upcoming}
            seasonKey={seasonKey}
            trackingData={
              upcoming ? { ...upcomingPerformancesTrackingData, meta: { viewMode } } : pastPerformancesTrackingData
            }
          />
        </div>
      )}
    </div>
  );
};

const SeasonOptionBlock = ({ data, linkProps, isActive = false, trackingData }) => (
  <LinkButton
    variant="native"
    styles={{
      root: classnames(classes.seasonOptions__item, {
        [classes.seasonOptions__type]: data?.isHeader,
        [classes.seasonOptions__type_upcoming]: data?.type === PERFORMANCE_TYPES.UPCOMING,
        [classes.active]: isActive,
        [classes.skipBorder]: data?.skipBorder,
      }),
    }}
    {...linkProps}
    isLink
    trackingData={trackingData}
  >
    <div className={classes.seasonOptionBlock}>
      <Typography className={classes.seasonOptionBlock__title} variant="p" size={14} weight="bold" truncate>
        {data?.title}
      </Typography>
      <Typography className={classes.seasonOptionBlock__subtitle} variant="p" size={13} color="secondary" truncate>
        {data?.subtitle}
      </Typography>
    </div>
    {data?.isHeader && <SpriteIcon className={classes.seasonOptionBlock__icon} icon="chevron_right" />}
  </LinkButton>
);

const SeasonOptions = ({ options, isActiveSection, activeSeasonId, trackingData }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { entity, entityType, navigate } = usePageContext();
  const { getSubPath } = useMemo(
    () =>
      navigate.getLinkProps({
        entity,
        entityType,
        path: ENTITY_MAIN_TABS.PERFORMANCES,
        pro: false,
      }),
    [navigate, entity, entityType],
  );

  const generateLinkTitle = useCallback(
    season => {
      if (season?.id) {
        return t(`${TP}.FN_PERFORMANCES_OF_ENTITY_SEASON`, { entity: entity?.name, season: season?.title });
      }

      if (season?.type === PERFORMANCE_TYPES.PAST) {
        return t(`${TP}.FN_ENTITY_PAST_PERFORMANCES_TITLE`, { entity: entity?.name });
      }

      return t(`${TP}.FN_ENTITY_UPCOMING_PERFORMANCES_TITLE`, { entity: entity?.name });
    },
    [t, entity?.name],
  );

  const getSeasonFilterLinkProps = useCallback(
    season =>
      getSubPath({
        title: generateLinkTitle(season),
        path: !season?.id && season?.type === PERFORMANCE_TYPES.PAST ? ENTITY_DETAILS_TAB.PAST : null,
        ...(season?.id && {
          filters: {
            season_id: [
              {
                id: season?.id,
                slug: '',
              },
            ],
          },
        }),
        onlyLinkProps: true,
      }),
    [generateLinkTitle, getSubPath],
  );

  return (
    <div className={classes.seasonOptions}>
      {options?.map((option, index) => (
        <SeasonOptionBlock
          key={index}
          data={option}
          isActive={
            (activeSeasonId && option?.id === activeSeasonId) || (!activeSeasonId && option.isHeader && isActiveSection)
          }
          linkProps={getSeasonFilterLinkProps(option)}
          trackingData={{
            ...trackingData,
            meta: {
              ...(option?.id && { year: option?.id }),
              ...(option?.isHeader && { isHeaderClicked: true }),
            },
          }}
        />
      ))}
    </div>
  );
};

export const PerformanceFilters = ({ upcoming = false, trackingData }) => {
  const { entityType } = usePageContext();
  const filters = useSeasonFilters({ upcoming });
  const applicableFilters = useMemo(() => {
    const validFilters = getValidFilters({ entityType, mainPath: ENTITY_MAIN_TABS.PERFORMANCES });

    return validFilters?.filter(type => type !== FILTER_TYPES.SEASON);
  }, [entityType]);

  return (
    <FilterFacets
      types={applicableFilters}
      mainPath={ENTITY_MAIN_TABS.PERFORMANCES}
      clearExcludedTypes={[FILTER_TYPES.SEASON]}
      commonFilterParams={filters}
      upcoming={upcoming}
      layout={FILTER_LAYOUT.HORIZONTAL}
      scrollable
      trackingData={trackingData}
    />
  );
};

const SectionBlockWithFilters = ({
  title,
  linkProps,
  enableSeeAll,
  withTitle,
  upcoming = false,
  children,
  sectionProps,
  seeAllTrackingData,
}) => {
  const { entityType } = usePageContext();
  const filters = useSeasonFilters({ upcoming });
  const filterProps = useMemo(() => {
    const validFilters = getValidFilters({ entityType, mainPath: ENTITY_MAIN_TABS.PERFORMANCES });

    const applicableFilters = validFilters?.filter(type => type !== FILTER_TYPES.SEASON);

    return {
      types: applicableFilters,
      commonFilterParams: filters,
      mainPath: ENTITY_MAIN_TABS.PERFORMANCES,
      upcoming,
      clearExcludedTypes: [FILTER_TYPES.SEASON],
    };
  }, [entityType, filters, upcoming]);

  return (
    <SectionBlock
      title={title}
      linkProps={linkProps}
      enableSeeAll={enableSeeAll}
      withTitle={withTitle}
      filterProps={filterProps}
      {...sectionProps}
      seeAllTrackingData={seeAllTrackingData}
    >
      {children}
    </SectionBlock>
  );
};

export const Seasons = ({ entity, entityType, sectionProps = {}, isOverview }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { mainPath, subPath, navigate, filterParams, pro } = usePageContext();
  const track = useTracking();

  const { pastSeasonFilters, upcomingSeasonFilters } = useMemo(
    () => ({
      pastSeasonFilters: getBaseSeasonFilters({ entityType, entity, upcoming: false }),
      upcomingSeasonFilters: getBaseSeasonFilters({ entityType, entity, upcoming: true }),
    }),
    [entityType, entity],
  );

  const linkProps = useMemo(
    () =>
      navigate.getLinkProps({ entityType, entity, path: ENTITY_MAIN_TABS.PERFORMANCES, ...(!pro && { pro: false }) }),
    [navigate, entityType, entity, pro],
  );

  const upcomingLinkProps = useMemo(() => {
    if (entity?.stats?.productions?.future?.exists) {
      return linkProps;
    }

    return linkProps.getSubPath({ path: ENTITY_DETAILS_TAB.PAST });
  }, [linkProps, entity]);

  const isUpcomingProduction = subPath !== ENTITY_DETAILS_TAB.PAST && !filterParams?.season_id?.[0];

  const seasonType = useMemo(() => {
    let type = SEASON_TYPES.CALENDAR_YEAR;

    if (entityType === ENTITY_TYPE.ORGANIZATION) {
      type =
        entity?.metadata?.find(({ name }) => name === ENTITY_META_DETA_TYPES.SEASON)?.value ||
        SEASON_TYPES.CALENDAR_YEAR;
    }

    return type;
  }, [entity, entityType]);

  const { data: upcomingSeasonList } = useQuery(
    queries.getSeasons({
      filters: { ...upcomingSeasonFilters, aggregation_season_type: seasonType },
      queryConfig: { enabled: entity?.stats?.productions?.future?.exists },
    }),
  );
  const { data: pastSeasonList } = useQuery(
    queries.getSeasons({
      filters: { ...pastSeasonFilters, aggregation_season_type: seasonType },
      queryConfig: { enabled: entity?.stats?.productions?.past?.exists },
    }),
  );

  const selectedSeason = useMemo(() => {
    const appliedSeasonId = filterParams?.season_id?.[0];

    if (appliedSeasonId) {
      const appliedSeason = pastSeasonList?.find(({ id }) => id === appliedSeasonId);

      if (appliedSeason) {
        return {
          id: appliedSeason?.id,
          type: PERFORMANCE_TYPES.PAST,
        };
      }
    } else if (subPath !== ENTITY_DETAILS_TAB.PAST && upcomingSeasonList?.length > 0) {
      return {
        id: null,
        type: PERFORMANCE_TYPES.UPCOMING,
      };
    }

    return {
      id: null,
      type: PERFORMANCE_TYPES.PAST,
    };
  }, [subPath, upcomingSeasonList, pastSeasonList, filterParams]);

  const seasonOptions = useMemo(() => {
    const lastUpcomingSeason = upcomingSeasonList?.[0]?.id + (seasonType === SEASON_TYPES.CALENDAR_YEAR ? 0 : 1);
    let upcomingSeasonsRange;

    if (lastUpcomingSeason) {
      upcomingSeasonsRange = t('UPCOMING_SEASONS_RANGE');
    }

    const upcoming = [
      {
        title: t(`${TP}.FN_UPCOMING`),
        subtitle: upcomingSeasonsRange,
        type: PERFORMANCE_TYPES.UPCOMING,
        skipBorder: true,
        isHeader: true,
      },
    ];

    const firstPastSeason = pastSeasonList?.[pastSeasonList?.length - 1]?.id;
    const lastPastSeason = pastSeasonList?.[0]?.id + (seasonType === SEASON_TYPES.CALENDAR_YEAR ? 0 : 1);
    let pastSeasonsRange;

    if (firstPastSeason === lastPastSeason) {
      pastSeasonsRange = firstPastSeason;
    } else if (firstPastSeason && lastPastSeason) {
      pastSeasonsRange = t('PAST_SEASONS_RANGE', {
        start_year: firstPastSeason,
      });
    }

    const past = [
      {
        title: t(`${TP}.FN_PAST`),
        subtitle: pastSeasonsRange,
        isHeader: true,
        type: PERFORMANCE_TYPES.PAST,
      },
      ...(pastSeasonList?.map(season => ({
        title: season?.name,
        subtitle: (
          <>
            <Typography variant="p" size={13} color="secondary" truncate>
              {season?.total} {t(`${TP}.m_PROD`)}
            </Typography>
            <Typography variant="p" size={12} color="secondary" truncate>
              {season?.perfCount} {t(`${TP}.FN_PERFORMANCES`)}
            </Typography>
          </>
        ),
        id: season?.id,
        key: season?.key,
        type: PERFORMANCE_TYPES.PAST,
      })) || []),
    ];

    return {
      upcoming: upcomingSeasonList?.length > 0 ? upcoming : null,
      past: pastSeasonList?.length > 0 ? past : null,
      ranges: {
        upcoming: upcomingSeasonsRange,
        past: pastSeasonsRange,
      },
    };
  }, [t, upcomingSeasonList, pastSeasonList, seasonType]);

  if (mainPath && !isOverview) {
    return (
      <SectionBlock linkProps={upcomingLinkProps} enableSeeAll={!mainPath} withTitle={!mainPath} {...sectionProps}>
        <>
          <div className={classes.root}>
            <div className={classnames(classes.root__seasons, { [classes.root__seasons_overview]: !mainPath })}>
              {seasonOptions?.upcoming && (
                <SeasonOptions
                  options={seasonOptions?.upcoming}
                  isActiveSection={selectedSeason?.type === PERFORMANCE_TYPES.UPCOMING}
                  activeSeasonId={selectedSeason?.id}
                  trackingData={upcomingPerformanceSeasonSelectorTrackingData}
                />
              )}
              {seasonOptions?.past && (
                <SeasonOptions
                  options={seasonOptions?.past}
                  isActiveSection={selectedSeason?.type === PERFORMANCE_TYPES.PAST}
                  activeSeasonId={selectedSeason?.id}
                  trackingData={pastPerformanceSeasonSelectorTrackingData}
                />
              )}
            </div>
            <div className={classes.root__productions}>
              <SeasonProductionList
                seasonId={selectedSeason?.id}
                seasonRange={isUpcomingProduction ? seasonOptions?.ranges?.upcoming : seasonOptions?.ranges?.past}
                seasonsList={isUpcomingProduction ? upcomingSeasonList : pastSeasonList}
                upcoming={isUpcomingProduction}
                sectionProps={sectionProps}
              />
            </div>
          </div>
        </>
      </SectionBlock>
    );
  }

  const title = entityType === ENTITY_TYPE.ARTIST ? `${TP}.FN_CREDITS` : `${TP}.FN_SEASONS_SECTION_TITLE`;
  const pastProductionLinkProps = linkProps.getSubPath({ path: ENTITY_DETAILS_TAB.PAST, onlyLinkProps: true });

  return (
    <SectionBlockWithFilters
      title={t(title)}
      linkProps={pastProductionLinkProps}
      enableSeeAll={isOverview}
      withTitle={isOverview}
      sectionProps={sectionProps}
      seeAllTrackingData={seeAllPerformanceCreditsTrackingData}
    >
      <div className={classes.overviewCredits}>
        {seasonOptions?.upcoming && (
          <Typography
            variant="p"
            className={classes.seasonGroup}
            onClick={() => {
              navigate.to(linkProps);
              track.click(upcomingPerformanceHeaderTrackingData);
            }}
          >
            <Typography weight="bold" className={classes.seasonGroup__title}>
              {t(`${TP}.FN_UPCOMING_PERFORMANCES`)}
            </Typography>
            {seasonOptions?.ranges?.upcoming && (
              <>
                {' '}
                <Typography className={classes.seasonGroup__range} size={12} color="secondary">
                  {seasonOptions?.ranges?.upcoming}
                </Typography>
              </>
            )}
            <SpriteIcon className={classes.seasonGroup__icon} icon="chevron_right" />
          </Typography>
        )}
        {seasonOptions?.past && (
          <Typography
            variant="p"
            className={classes.seasonGroup}
            onClick={() => {
              navigate.to(pastProductionLinkProps);
              track.click(pastPerformanceHeaderTrackingData);
            }}
          >
            <Typography weight="bold" className={classes.seasonGroup__title}>
              {t(`${TP}.FN_PAST_PERFORMANCES`)}
            </Typography>
            {seasonOptions?.ranges?.past && (
              <>
                {' '}
                <Typography className={classes.seasonGroup__range} size={12} color="secondary">
                  {seasonOptions?.ranges?.past}
                </Typography>
              </>
            )}
            <SpriteIcon className={classes.seasonGroup__icon} icon="chevron_right" />
          </Typography>
        )}
        <ProductionListingWrapper viewMode={VIEW_TYPES.SEASON} trackingData={overviewCreditsTrackingDataSlug} />
        <TertiaryButton
          shape="rectangle"
          rightIcon={<SpriteIcon icon="chevron_right" />}
          trackingData={seeAllPerformanceCreditsTrackingData}
          {...pastProductionLinkProps}
          isLink
          stretch
        >
          {t(`${TP}.m_ARTICLES_SEE_ALL`)} {t(title)}
        </TertiaryButton>
      </div>
    </SectionBlockWithFilters>
  );
};

const SeasonsWrapper = ({ entity, entityType, isEditMode, isOverview }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate } = usePageContext();

  const sections = useMemo(() => {
    const { getSubPath } = navigate.getLinkProps({ entity, entityType, edit: true });
    const components = [
      {
        component: Seasons,
        props: {
          isOverview,
        },
        ...(isEditMode && {
          sectionProps: {
            title: t(`${TP}.m_PERF`),
            emptySections: createHorizontalSections([
              {
                title: t(`${TP}.FN_ADD_DATA_TO_SECTION`, { section: t(`${TP}.m_PERF`).toLowerCase() }),
                linkProps: getSubPath({
                  queryParams: {
                    tab: artistEditTabValues.schedule,
                    [VALID_QUERY_PARAMS.OPEN_MODAL]: PERFORMANCE_SECTION.ADD_PERFORMANCE,
                  },
                }),
              },
            ]),
            editMode: {
              enabled: isEditMode,
              hasContent: entity?.stats?.productions?.exists,
              linkProps: getSubPath({
                queryParams: {
                  tab: artistEditTabValues.schedule,
                },
              }),
              addLinkProps: getSubPath({
                queryParams: {
                  tab: artistEditTabValues.schedule,
                  [VALID_QUERY_PARAMS.OPEN_MODAL]: PERFORMANCE_SECTION.ADD_PERFORMANCE,
                },
              }),
            },
            enableSeeAll: false,
          },
        }),
        showSection: isEditMode || entity?.stats?.productions?.exists,
      },
    ];

    return components;
  }, [entity, entityType, isEditMode, navigate, t, isOverview]);

  return <Content entity={entity} entityType={entityType} sections={sections} />;
};

export default SeasonsWrapper;
